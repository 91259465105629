import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/Index'
import Permit from '@/components/Permit'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Router)
Vue.use(ElementUI);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/permit',
      name: 'permit',
      component: Permit
    }
  ],
  mode: 'hash'
})


<template>
  <el-image src="../assets/permitImg.jpg"> </el-image>
</template>

<script>
export default {
  name: 'permit',
  data () {
    return {
      // permitImg:require('../assets/permitImg.jpg'),
    }
  },
  mounted(){
    
  },
  methods:{
  }
}
</script>

<style>

</style>

<template>
  <div id="container" style=""></div>
</template>

<script>
export default {
  name: 'index',
  props: {
    lnglat: {
      default: function () {
        return []
      }
    },
    address: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cityLocation: ''
    }
  },
  created () {},
  mounted () {
    var infoWindow
    var map = new AMap.Map('container', {
      resizeEnable: true,
      center: this.lnglat,
      zoom: 16
    })

    // 1.在指定位置打开信息窗体,构建信息窗体中显示的内容
    var info = []
    info.push(`<p>${this.address}</p></div></div>`)

    infoWindow = new AMap.InfoWindow({
      content: info.join('') // 使用默认信息窗体框样式，显示信息内容
    })

    infoWindow.open(map, map.getCenter())

    // // 2.定义marker
    // var marker = new AMap.Marker({
    //   map: map,
    //   position: this.lnglat
    // })
    // // 鼠标点击marker弹出自定义的信息窗体
    // AMap.event.addListener(marker, 'click', function () {
    //   infoWindow.open(map, marker.getPosition())
    // })

    // 构造矢量圆形
    var circle = new AMap.Circle({
        center: new AMap.LngLat(this.lnglat[0],this.lnglat[1]), // 圆心位置
        radius: 1000,  //半径
        strokeColor: "#fff",  //线颜色
        strokeOpacity: 0,  //线透明度
        // strokeWeight: 3,  //线粗细度
        fillColor: "#fff",  //填充颜色
        fillOpacity: 0.5 //填充透明度
    });
    map.add(circle);
    map.setFitView();
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  height: 300px;
}
::v-deep .amap-info-close{
    display: none;
}
::v-deep .amap-info-content{
    background-color: rgba($color: #000000, $alpha: 0.7);
    color:#fff;
}
::v-deep .amap-info-sharp{
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba($color: #000000, $alpha: 0.7);
}
</style>

<template>
  <div id="page" class="scroll_content">
    <!-- <header
      :class="[fixed ? 'fixed' : '', deviceType == 'phone' ? 'phone' : '']"
    > -->
    <header :class="[fixed ? 'fixed' : '']">
      <el-row class="header-box">
        <el-col :span="8">
          <div class="logo"></div>
        </el-col>
        <el-col :span="8">
          <div class="link-m">
            <div
              :class="[currents == 1 ? 'active' : '', 'link']"
              @click="toScroll(1)"
            >
              主页
            </div>
            <div
              :class="[currents == 2 ? 'active' : '', 'link']"
              @click="toScroll(2)"
            >
              品牌介绍
            </div>
            <div
              :class="[currents == 3 ? 'active' : '', 'link']"
              @click="toScroll(3)"
            >
              关于我们
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="link-r">
            <div class="link about" @click="toScroll(5)">关注我们</div>
            <div class="link contact" @click="toScroll(4)">联系我们</div>
          </div>
        </el-col>
      </el-row>
    </header>
    <section class="banner"></section>
    <section class="introduce scroll_title">
      <h2 class="title">
        <img src="../assets/safe.png" alt="" /> 菜划算进货-进货就要菜划算
      </h2>
      <div class="title-line"></div>
      <div class="text">
        菜划算主要从事生鲜供应链新零售业务。带着让餐饮更简单、让农业更美好的使命。我们致力于通过互联网技术手段，建立从田间地头到餐桌的数字化产业新基建。售卖商品覆盖餐饮全品类，建立自己的仓储运输及生产加工服务体系。用心服务好每个商户，携手数百万生态伙伴共同创造美好生活！
      </div>
      <div class="box-container clearfloat">
        <div class="box">
          <div class="icon ic-1"></div>
          <div class="tit">品质保障</div>
          <div class="chinese">高品质食材，安心又放心</div>
          <div class="english">High quality fresh, rest assured</div>
        </div>
        <div class="box">
          <div class="icon ic-2"></div>
          <div class="tit">物美价廉</div>
          <div class="chinese">进货就要菜划算</div>
          <div class="english">Purchase will use Caihuasuan</div>
        </div>
        <div class="box">
          <div class="icon ic-3"></div>
          <div class="tit">轻松下单</div>
          <div class="chinese">掌上选品，手机下单，送货到店</div>
          <div class="english">Mobile phone purchase，delivery store</div>
        </div>
        <div class="box">
          <div class="icon ic-4"></div>
          <div class="tit">原产地供给</div>
          <div class="chinese">源头货源，新鲜产，新鲜送</div>
          <div class="english">Fresh produce，fresh delivery</div>
        </div>
        <div class="box">
          <div class="icon ic-5"></div>
          <div class="tit">自营供应链</div>
          <div class="chinese">层层筛选，实力保障</div>
          <div class="english">Layer screening, strength protection</div>
        </div>
        <div class="box">
          <div class="icon ic-6"></div>
          <div class="tit">冷链保鲜</div>
          <div class="chinese">冷链运输，专业设备，全程保鲜</div>
          <div class="english">Professional equipment, full fresh</div>
        </div>
      </div>
    </section>
    <section class="carousel scroll_title">
      <div class="bak1">
        <p>我们精选</p>
        <p>多品类数千款商品</p>
      </div>
      <div class="bak2">
        <p></p>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide slide1"></div>
          <div class="swiper-slide slide2"></div>
          <div class="swiper-slide slide3"></div>
          <div class="swiper-slide slide4"></div>
          <div class="swiper-slide slide5"></div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <section class="aboutus scroll_title">
      <h2 class="title">关于我们</h2>
      <div class="title-line"></div>
      <div class="img"></div>
    </section>
    <section class="contactus scroll_title">
      <h2 class="title">联系我们</h2>
      <div class="title-line"></div>
      <ampCurrent :lnglat="[120.190865,30.193091]" address="杭州菜划算网络科技有限公司" class="map"/>
      <!-- <ampCurrent :lnglat="[120.196786,30.196674]" address="杭州菜划算网络科技有限公司" class="map"/> -->
      <div class="tips">
        <el-row>
          <el-col :span="8">
            <div class="icon ic-1"></div>
            <div class="title">公司地址</div>
            <div class="detail">
              杭州市滨江区阿里中心（滨兴路1866号）4号楼9楼
            </div>
          </el-col>
          <el-col :span="8">
            <div class="icon ic-2"></div>
            <div class="title">联系电话</div>
            <div class="detail">400-903-9878</div>
          </el-col>
          <el-col :span="8">
            <div class="icon ic-3"></div>
            <div class="title">工作时间</div>
            <div class="detail">早上8:00-晚上22:00</div>
          </el-col>
        </el-row>
      </div>
    </section>
    <footer>
      <div class="ftop scroll_title">
        <div class="container">
          <div class="info">
            <div class="logo"></div>
            <div class="intro">
              菜划算进货，致力于提高天下千万餐厅的食材水平，以国内自营供应链和先进互联网技术驱动的餐饮供应链电商平台，希望为每一位商家打造“高品质、低价格”的一站式食材采购体验。
            </div>
          </div>

          <div class="code">
            <div class="card">
              <img src="../assets/chsApp.png" alt="" />
              <div class="tit">菜划算进货客户端</div>
            </div>
            <!-- <div class="card">
              <img src="../assets/mp.jpg" alt="">
              <div class="tit">菜划算小程序</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="fbtm">
        <!-- <div class="copyright">Copyright ©2019 Hangzhou cuisine Network Technology Co., Ltd.</div> -->
        <!-- <div class="copyright" style="text-decoration:underline;cursor:pointer" @click="handleToPermit">食品经营许可证</div> -->
        <div class="record">
          <a
            target="_blank"
            href="http://beian.miit.gov.cn/"
            style="color: #fff; text-decoration: none"
            >浙ICP备18043056号</a
          >
        </div>
        <div class="record">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/index.do"
            style="color: #fff; text-decoration: none"
            >浙公网安备 33010802010103号</a
          >
        </div>
        <div class="copyright">
          Copyright ©{{currentYear}} 杭州菜划算网络科技有限公司 版权所有
        </div>
        <!-- <div class="record">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010103" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">浙公网安备 33010802010103号</p></a>
        </div> -->
      </div>
    </footer>
  </div>
</template>

<script>
window.onresize = function () {
  document.getElementById("page").style.height =
    document.documentElement.clientHeight + "px";
};
export default {
  name: "index",
  components: {
    ampCurrent: () => import('./amp-current')
  },
  data() {
    return {
      center: { lng: 0, lat: 0 }, //经纬度
      zoom: 3, //地图展示级别
      currents: "",
      fixed: false,
      cover: require("../assets/cover.png"),
      tips: require("../assets/tips.png"),
      // deviceType: deviceType,
      currentYear:''
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear()
    document.getElementById("page").style.height =
      document.documentElement.clientHeight + "px";
    window.addEventListener("scroll", this.handleScroll, true);
    this.$nextTick(() => {
      var swiper = new Swiper(".swiper-container", {
        // eslint-disable-line
        loop: true,
        autoplay: true,
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    });
  },
  methods: {
    handleScroll() {
      let scrollTop = document.querySelector(".scroll_content").scrollTop;
      this.fixed = scrollTop >= 80 ? true : false;
    },
    toScroll(index) {
      this.currents = index;
      // 用 class="scroll_title" 添加锚点
      let jump = document.querySelectorAll(".scroll_title");
      let scroll_content = document.querySelector(".scroll_content");
      let total = jump[index - 1].offsetTop; //这里的500是我头部header的高度
      let distance = scroll_content.scrollTop; //获取需要滚动的距离
      console.log("total", total);
      console.log("distance", distance);
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          scroll_content.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          scroll_content.scrollTop = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          scroll_content.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          scroll_content.scrollTop = total;
        }
      }
    },
    handleToPermit() {
      this.$router.push({ path: "permit" });
    },
  },
};
</script>

<style lang="scss">
#page {
  // width: 1440px;
  margin: auto;
  text-align: center;
  overflow: scroll;
  header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background: #fff;
    border-bottom: 1px solid #eee;
    z-index: 999;
    &.fixed {
      position: fixed;
      opacity: 0.8;
      width: calc(100% - 17px);
      &.phone {
        width: 100%;
      }
    }
    .header-box {
      width: 1140px;
      margin: auto;
      .logo {
        width: 107px;
        height: 44px;
        background: url(../assets/logo.png) no-repeat center;
        margin-top: 18px;
      }
      .link-m {
        width: 250px;
        margin: auto;
        overflow: hidden;
        text-align: center;
        .link {
          float: left;
          font-size: 16px;
          margin-right: 40px;
          cursor: pointer;
          &.active {
            color: #fd7124;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .link-r {
        overflow: hidden;
        .link {
          float: right;
          margin-top: 25px;
          cursor: pointer;
          &.about {
            width: 100px;
            height: 30px;
            background: #fd7124;
            color: #fff;
            text-align: center;
            line-height: 30px;
            margin-left: 24px;
          }
          &.contact {
            width: 100px;
            height: 30px;
            border: 1px solid #fd7124;
            color: #fd7124;
            text-align: center;
            line-height: 30px;
          }
        }
      }
    }
  }
  .banner {
    min-width: 1440px;
    height: 420px;
    background: url(../assets/banner.png) no-repeat center;
    background-size: 100%;
  }
  .introduce {
    padding-top: 72px;
    .title {
      img {
        vertical-align: middle;
        margin-bottom: 10px;
      }
      font-size: 36px;
      line-height: 50px;
      font-weight: normal;
    }
    .title-line {
      width: 120px;
      height: 6px;
      background: #fd7124;
      margin: 24px auto 40px;
    }
    .text {
      width: 770px;
      height: 84px;
      font-size: 14px;
      line-height: 21px;
      color: #888;
      margin: auto;
    }
    .box-container {
      width: 1127px;
      margin: 40px auto;
      .box {
        width: 375px;
        height: 285px;
        background: #fff;
        border-right: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        float: left;
        &:nth-child(3n) {
          border-right: none;
        }
        &:nth-child(4) {
          border-bottom: none;
        }
        &:nth-child(5) {
          border-bottom: none;
        }
        &:nth-child(6) {
          border-bottom: none;
        }
        &:hover {
          box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.19);
          transform: scale(1.05);
        }
        .icon {
          width: 60px;
          height: 60px;
          margin: 24px auto;
        }
        .ic-1 {
          background: url(../assets/shape.png) no-repeat center;
        }
        .ic-2 {
          background: url(../assets/coupon.png) no-repeat center;
        }
        .ic-3 {
          background: url(../assets/APP.png) no-repeat center;
        }
        .ic-4 {
          background: url(../assets/ball.png) no-repeat center;
        }
        .ic-5 {
          background: url(../assets/net.png) no-repeat center;
        }
        .ic-6 {
          background: url(../assets/car.png) no-repeat center;
        }
        .tit {
          height: 37px;
          font-size: 26px;
          font-weight: 400;
          color: #333;
          line-height: 37px;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }
        .chinese,
        .english {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(136, 136, 136, 1);
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .carousel {
    position: relative;
    width: 1440px;
    height: 970px;
    margin: 80px auto 96px;
    overflow: hidden;
    .bak1 {
      float: left;
      width: 378px;
      height: 413px;
      margin: 30px 0 0 150px;
      padding: 40px 23px;
      background: url(../assets/bak1.png) no-repeat center;
      p {
        height: 52px;
        font-size: 40px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 48px;
        text-align: left;
      }
    }
    .bak2 {
      float: right;
      display: inline-block;
      width: 810px;
      height: 970px;
      background: #3c3d42;
      p {
        width: 681px;
        height: 46px;
        background: url(../assets/words.png) no-repeat center;
        margin: 107px 0 0 65px;
      }
    }
  }
  .aboutus {
    background: #f6f6f6;
    padding: 72px 0px 96px;
    .title {
      font-size: 36px;
      line-height: 50px;
      font-weight: normal;
    }
    .title-line {
      width: 120px;
      height: 6px;
      background: #fd7124;
      margin: 24px auto 40px;
    }
    .img {
      width: 1138px;
      height: 760px;
      margin: 72px auto 0;
      background: url(../assets/aboutus.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .contactus {
    padding: 72px 0 72px;
    .title {
      font-size: 36px;
      line-height: 50px;
      font-weight: normal;
    }
    .title-line {
      width: 120px;
      height: 6px;
      background: #fd7124;
      margin: 24px auto 40px;
    }
    .map {
      width: 1138px;
      height: 490px;
      margin: 72px auto;
    }
    .tips {
      max-width: 1440px;
      margin: auto;
      .icon {
        width: 64px;
        height: 64px;
        margin: auto;
        border-radius: 50%;
        box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.07),
          0px 15px 35px 0px rgba(50, 50, 93, 0.1);
      }
      .ic-1 {
        background: url(../assets/local.png) no-repeat center;
      }
      .ic-2 {
        background: url(../assets/tel.png) no-repeat center;
      }
      .ic-3 {
        background: url(../assets/time.png) no-repeat center;
      }
      .title {
        color: #333;
        font-size: 20px;
        line-height: 30px;
        margin-top: 16px;
      }
      .detail {
        color: #888;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
      }
    }
  }
  footer {
    .ftop {
      height: 340px;
      width: 100%;
      background: #3c3d42;
      .container {
        width: 1150px;
        padding-top: 75px;
        overflow: hidden;
        margin: auto;
        .info {
          float: left;
          .logo {
            width: 146px;
            height: 60px;
            background: url(../assets/logo_w.png) no-repeat center;
            margin: 20px 0;
          }
          .intro {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            width: 440px;
            text-align: left;
            padding-left: 8px;
          }
        }
        .code {
          float: right;
          display: flex;
          align-items: center;
          .card {
            margin-left: 30px;
            img {
              width: 160px;
              height: 160px;
            }
            .tit {
              color: #fff;
              font-size: 16px;
              line-height: 24px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .fbtm {
      background: #323337;
      overflow: hidden;
      padding: 40px 0;
      div {
        font-size: 14px;
        color: #fff;
        line-height: 20px;
        margin-bottom: 4px;
      }
    }
  }
}
/*清除浮动代码*/
.clearfloat:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}
.clearfloat {
  zoom: 1;
}

.el-carousel__arrow {
  width: 80px;
  height: 80px;
}
.el-icon-arrow-left {
  transform: scale(3);
}
.el-icon-arrow-right {
  transform: scale(3);
}
.el-carousel__arrow--right {
  right: 50px;
}
.el-carousel__arrow--left {
  left: 50px;
}

.swiper-container {
  width: 1320px;
  height: 650px;
  position: absolute;
  top: 150px;
  left: 445px;
  right: 0;
  bottom: 0;
  margin: auto;
  .swiper-pagination {
    width: 55% !important;
  }
}
.swiper-slide {
  width: 420px;
  height: 500px;
  border-radius: 6px;
  &.slide1 {
    background: url(../assets/prod1.png) no-repeat center;
  }
  &.slide2 {
    background: url(../assets/prod2.png) no-repeat center;
  }
  &.slide3 {
    background: url(../assets/prod3.png) no-repeat center;
  }
  &.slide4 {
    background: url(../assets/prod4.png) no-repeat center;
  }
  &.slide5 {
    background: url(../assets/prod5.png) no-repeat center;
  }
}
.swiper-pagination-bullet {
  background: url(../assets/oval.png) no-repeat center;
  width: 20px;
  height: 20px;
  margin: 0 8px;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}
</style>
